import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import * as Sentry from '@sentry/react';

import QuestionnaireHeader from 'components/molecules/QuestionnaireHeader';
import Pagination from 'components/molecules/Pagination';
import Question from '../../molecules/Question/Question';
import SubmitFormPage from 'components/molecules/SubmitFormPage';
import ThankYouPage from 'components/molecules/ThankYouPage';
import FinishedPage from 'components/molecules/FinishedPage';
import VoucherInvalidPage from 'components/molecules/VoucherInvalidPage';
import { Container, QuestionBox, Wrapper, Loading } from './styles';
import { loadState, saveState, purgeCache } from 'utils/localStorage';
import { CSSTransition } from 'react-transition-group';
import { generators, enhancements } from './generators';
import { isEmpty, omit } from 'lodash';
import { validate } from '../../../utils/helpers';
import { loadConfig } from '../../../utils/api';
import GlobalStyles from '../../organisms/App/GlobalStyles';
import { getCountryCode } from '../../../utils/location';

import axios from 'axios';

const formFactory = (schema) => schema.map((obj) => generators(obj));

const lightenColor = (col, amount) => {
  if (col === null || col === undefined || col === false) return null;

  if (col[0] == '#') col = col.slice(1);
  let num = parseInt(col, 16);

  let r = (num >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return '#' + (g | (b << 8) | (r << 16)).toString(16);
};

class Questionaire extends PureComponent {
  constructor(props) {
    super(props);
    const { valuationId, customMarketplaceId, voucher } = this.props.match.params;
    const { offersSent, forceFinished } = this.props;

    const formsubmitted = valuationId !== undefined; // we already have a submitted valuation here -> thank you screen
    const finished = formsubmitted;

    this.state = {
      loading: true,
      formFields: [],
      currentID: 1,
      currentPage: 0,
      formSubmitted: formsubmitted,
      wantsContact: offersSent,
      isEmailValid: false,
      isPhoneValid: false,
      userEmail: '',
      userPhone: '',
      finished: finished,
      formVersion: '',
      baseUrl: '',
      submitPath: '',
      privacyStatementUrl: '',
      valuationId: valuationId,
      customMarketplaceId: customMarketplaceId,
      tocLink: '',
      vouchersEnabled: false,
      voucher: voucher,
      voucher_invalid: false,
      collectPhoneNumber: false,
      requirePhoneNumber: false,
      forceFinished: forceFinished,
      gaTrackingId: '',
      userCountry: getCountryCode(),
    };
  }

  async componentDidMount() {
    const state = loadState();
    let response = {};
    try {
      response = await loadConfig(this.state.customMarketplaceId, this.state.voucher);
    } catch (error) {
      this.setState({
        voucher_invalid: true,
        loading: false,
      });
      return;
    }

    let marketplaceBaseUrl = response.baseUrl.includes('staging')
      ? 'https://my.staging.valutico.dev'
      : 'https://my.valutico.com';

    if (state && response.formVersion === state.formVersion) {
      this.setState({
        loading: false,
        formFields: state.formFields,
        wantsContact: this.state.wantsContact || response.autoContact,
        autoContact: response.autoContact,
        formVersion: response.formVersion,
        baseUrl: response.baseUrl,
        marketplaceBaseUrl: marketplaceBaseUrl,
        submitPath: response.submitPath,
        privacyStatementUrl: response.privacyStatementUrl,
        logoHidden: response.logoHidden || false,
        socialsHidden: response.socialsHidden || false,
        primaryColor: response.primaryColor || null,
        primaryColorLight: lightenColor(response.primaryColor, 20),
        secondaryColor: response.secondaryColor || null,
        secondaryColorLight: lightenColor(response.secondaryColor, 50),
        intercomEnabled: response.intercomEnabled || false,
        tocLink: response.tocLink || false,
        vouchersEnabled: response.vouchersEnabled || false,
        collectPhoneNumber: response.collectPhoneNumber || false,
        requirePhoneNumber: response.requirePhoneNumber || false,
        gaTrackingId: response.gaTrackingId,
      });
    } else {
      this.setState({
        loading: false,
        formFields: formFactory(response.questions),
        formVersion: response.formVersion,
        baseUrl: response.baseUrl,
        marketplaceBaseUrl: marketplaceBaseUrl,
        wantsContact: this.state.wantsContact || response.autoContact,
        autoContact: response.autoContact,
        submitPath: response.submitPath,
        privacyStatementUrl: response.privacyStatementUrl,
        logoHidden: response.logoHidden || false,
        socialsHidden: response.socialsHidden || false,
        primaryColor: response.primaryColor || null,
        primaryColorLight: lightenColor(response.primaryColor, 20),
        secondaryColor: response.secondaryColor || null,
        secondaryColorLight: lightenColor(response.secondaryColor, 50),
        intercomEnabled: response.intercomEnabled || false,
        tocLink: response.tocLink || false,
        vouchersEnabled: response.vouchersEnabled || false,
        collectPhoneNumber: response.collectPhoneNumber || false,
        requirePhoneNumber: response.requirePhoneNumber || false,
        gaTrackingId: response.gaTrackingId,
      });
    }
  }

  // set the inital value for input select type questions
  // NOTE: it made for grouped select options
  checkInitialInputSelectValue = (obj) => {
    const index = this.state.formFields.findIndex((field) => field.id === obj.id);
    if (!isEmpty(this.state.formFields[index].selectedValue)) {
      return;
    }
    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            selectedValue: this.state.formFields[index].options[0].options[0],
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  persistState = () => {
    saveState({
      formFields: this.state.formFields,
      formVersion: this.state.formVersion,
    });
  };

  changePage = (direction) => {
    const directions = {
      INC: this.state.currentPage + 1,
      DEC: this.state.currentPage - 1,
    };

    const nextPage = directions[direction];

    if (nextPage >= 0) {
      this.setState({
        currentPage: nextPage,
      });
    } else {
      this.props.history.push('/');
    }
  };

  handleUserMail = (value) => {
    const mailRegex = /[^@]+@[^\.]+\..+/g;
    const isEmailValid = mailRegex.test(value);
    this.setState({ userEmail: value, isEmailValid: isEmailValid });
  };

  handleUserPhone = (value) => {
    const phoneRegex =
      /^(\+|00)((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/g;
    const isPhoneValid = phoneRegex.test(value);
    this.setState({ userPhone: value, isPhoneValid: isPhoneValid });
  };

  handleConsentDecision = (value) => {
    const consentData = {
      marketplace_valuation: {
        id: this.state.valuationId,
        wants_contact: value,
      },
    };

    axios
      .patch(`${this.state.baseUrl}${this.state.submitPath}/${this.state.valuationId}`, consentData)
      .then((response) => {
        this.setState({
          finished: true,
          wantsContact: value,
        });
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  handleSubmit = (data) => {
    window.Intercom('update', { "email": this.state.userEmail });

    const finalData = {
      marketplace_valuation: {
        client_email: this.state.userEmail,
        client_phone: this.state.userPhone,
        locale: i18next.language,
        questionnaire: this.state.formFields.map((obj) => omit(obj, Object.keys(enhancements[obj.type]))),
      },
    };

    let newState = {
      formSubmitted: true,
    };

    if (this.state.wantsContact) {
      finalData.marketplace_valuation.wants_contact = this.state.wantsContact;
      newState.finished = true;
    }

    if (this.state.voucher) {
      finalData.marketplace_valuation.voucher_code = this.state.voucher;
    }

    axios
      .post(`${this.state.baseUrl}${this.state.submitPath}`, finalData)
      .then((response) => {
        newState.valuationId = response.data.valuation_id;
        this.setState(newState, () => {
          purgeCache();
        });
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
      });
  };

  handleInputChange = (obj, value) => {
    const index = this.state.formFields.findIndex((field) => field.id === obj.id);

    if (obj.type === 'input_with_select') {
      this.setState(
        (prevState) => ({
          formFields: [
            ...prevState.formFields.slice(0, index),
            {
              ...prevState.formFields[index],
              value: value,
              isDirty: true,
              isValid: !isEmpty(value) && validate(value, this.state.formFields[index].validationRule),
            },
            ...prevState.formFields.slice(index + 1),
          ],
        }),
        () => {
          this.persistState();
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          formFields: [
            ...prevState.formFields.slice(0, index),
            {
              ...prevState.formFields[index],
              value: value,
              isDirty: true,
              isValid: !isEmpty(value),
            },
            ...prevState.formFields.slice(index + 1),
          ],
        }),
        () => {
          this.persistState();
        }
      );
    }
  };

  handleCheckboxChange = (optionIndex, fieldId) => {
    const index = this.state.formFields.findIndex((field) => field.id === fieldId);

    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            options: [
              ...prevState.formFields[index].options.slice(0, optionIndex),
              {
                ...prevState.formFields[index].options[optionIndex],
                value: !prevState.formFields[index].options[optionIndex].value,
              },
              ...prevState.formFields[index].options.slice(optionIndex + 1),
            ],
            isValid: true,
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  handleRangeInputChange = (questionIndex, fieldId, value) => {
    const index = this.state.formFields.findIndex((field) => field.id === fieldId);

    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            questions: [
              ...prevState.formFields[index].questions.slice(0, questionIndex),
              {
                ...prevState.formFields[index].questions[questionIndex],
                value: value,
              },
              ...prevState.formFields[index].questions.slice(questionIndex + 1),
            ],
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  handleOpenSelectionRadioChange = (fieldData, radioData) => {
    const index = this.state.formFields.findIndex((field) => field.id === fieldData.id);

    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            value: radioData,
            isValid: !isEmpty(radioData.label),
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  handleOpenSelectionInputChange = (fieldData, RadioIndex, option, value) => {
    const index = this.state.formFields.findIndex((field) => field.id === fieldData.id);

    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            options: [
              ...prevState.formFields[index].options.slice(0, RadioIndex),
              {
                ...prevState.formFields[index].options[RadioIndex],
                label: value,
              },
              ...prevState.formFields[index].options.slice(RadioIndex + 1),
            ],
            value: option,
            isValid: true,
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  handleInputSelectClick = (option, obj) => {
    const index = this.state.formFields.findIndex((field) => field.id === obj.id);

    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            selectedValue: option,
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  handleAutocompleteClick = (option, obj) => {
    const index = this.state.formFields.findIndex((field) => field.id === obj.id);

    const isValid = !isEmpty(option) || !obj.required;
    this.setState(
      (prevState) => ({
        formFields: [
          ...prevState.formFields.slice(0, index),
          {
            ...prevState.formFields[index],
            value: option,
            isValid: isValid,
          },
          ...prevState.formFields.slice(index + 1),
        ],
      }),
      () => {
        this.persistState();
      }
    );
  };

  render() {
    if (
      this.state.customMarketplaceId === null ||
      this.state.customMarketplaceId === undefined ||
      this.state.intercomEnabled
    ) {
      window.Intercom('boot', {
        app_id: APP_ID,
      });
    }

    const numberOfQuestions = this.state.formFields.length;
    const onTheSubmitPage = this.state.currentPage === numberOfQuestions;
    const isInIFrame = window.location !== window.parent.location;

    const {
      formFields,
      currentPage,
      baseUrl,
      marketplaceBaseUrl,
      loading,
      formSubmitted,
      wantsContact,
      autoContact,
      finished,
      privacyStatementUrl,
      userEmail,
      userPhone,
      isEmailValid,
      isPhoneValid,
      voucher_invalid,
      forceFinished,
    } = this.state;

    return (
      <>
        <GlobalStyles
          primaryColor={this.state.primaryColor}
          primaryColorLight={this.state.primaryColorLight}
          secondaryColor={this.state.secondaryColor}
          secondaryColorLight={this.state.secondaryColorLight}
        />
        <Container>
          <QuestionnaireHeader
            isInIFrame={isInIFrame}
            logoHidden={this.state.logoHidden}
            primaryColor={this.state.primaryColor}
            primaryColorLight={this.state.primaryColorLight}
            secondaryColor={this.state.secondaryColor}
            secondaryColorLight={this.state.secondaryColorLight}
          />
          <QuestionBox>
            {!loading && onTheSubmitPage && !formSubmitted && !voucher_invalid && !forceFinished && (
              <SubmitFormPage
                handleSubmit={this.handleSubmit}
                handleUserMail={this.handleUserMail}
                handleUserPhone={this.handleUserPhone}
                privacyStatementUrl={privacyStatementUrl}
                userEmail={userEmail}
                userPhone={userPhone}
                isEmailValid={isEmailValid}
                isPhoneValid={isPhoneValid}
                primaryColor={this.state.primaryColor}
                primaryColorLight={this.state.primaryColorLight}
                secondaryColor={this.state.secondaryColor}
                secondaryColorLight={this.state.secondaryColorLight}
                tocLink={this.state.tocLink}
                vouchersEnabled={this.state.vouchersEnabled}
                collectPhoneNumber={this.state.collectPhoneNumber}
                requirePhoneNumber={this.state.requirePhoneNumber}
                userCountry={this.state.userCountry}
              />
            )}
            {!onTheSubmitPage && !formSubmitted && !voucher_invalid && !forceFinished && (
              <Wrapper>
                {formFields.map((data, index) => {
                  return (
                    <CSSTransition
                      in={index === currentPage}
                      timeout={300}
                      key={data.id}
                      classNames="questionnaire__question"
                      unmountOnExit
                    >
                      <Question
                        // =data=
                        fieldData={data}
                        currentPage={currentPage + 1}
                        maxSteps={numberOfQuestions + 1} //to include submit page
                        // =data handlers=
                        // handler for textareas and single inouts
                        handleChange={this.handleInputChange}
                        // handler for checkboxes
                        handleCheckboxChange={this.handleCheckboxChange}
                        // handler for range inputs
                        handleRangeInputChange={this.handleRangeInputChange}
                        // handler for input + select - select
                        handleInputSelectClick={this.handleInputSelectClick}
                        // handler for autocomplete
                        handleAutocompleteClick={this.handleAutocompleteClick}
                        // handler for input type radio
                        handleOpenSelectionRadioChange={this.handleOpenSelectionRadioChange}
                        // handler for input type text in free form
                        handleOpenSelectionInputChange={this.handleOpenSelectionInputChange}
                        // handler for setting up the initial input + select value
                        checkInitialInputSelectValue={this.checkInitialInputSelectValue}
                        baseUrl={baseUrl}
                        primaryColor={this.state.primaryColor}
                        secondaryColor={this.state.secondaryColor}
                      />
                    </CSSTransition>
                  );
                })}
              </Wrapper>
            )}
            {!formSubmitted && !loading && !voucher_invalid && !forceFinished && (
              <Pagination
                previousQuestionDisabled={false}
                nextQuestionDisabled={onTheSubmitPage || !formFields[currentPage].isValid}
                changePage={this.changePage}
                currentPage={currentPage + 1}
                maxSteps={numberOfQuestions + 1} //to include submit page
                isSubmitPage={onTheSubmitPage}
                isInIFrame={isInIFrame}
                primaryColor={this.state.primaryColor}
                primaryColorLight={this.state.primaryColorLight}
                secondaryColor={this.state.secondaryColor}
                secondaryColorLight={this.state.secondaryColorLight}
              />
            )}
            {formSubmitted && !finished && !voucher_invalid && !forceFinished && (
              <ThankYouPage
                handleConsentDecision={this.handleConsentDecision}
                primaryColor={this.state.primaryColor}
                primaryColorLight={this.state.primaryColorLight}
                secondaryColor={this.state.secondaryColor}
                secondaryColorLight={this.state.secondaryColorLight}
              />
            )}
            {((finished && !voucher_invalid) || forceFinished) && (
              <FinishedPage
                wantsContact={wantsContact}
                autoContact={autoContact}
                baseUrl={baseUrl}
                marketplaceBaseUrl={marketplaceBaseUrl}
                shareUrl={window.location.host}
                socialsHidden={this.state.socialsHidden}
                primaryColor={this.state.primaryColor}
                secondaryColor={this.state.secondaryColor}
                customMarketplaceId={this.state.customMarketplaceId}
              />
            )}
            {voucher_invalid && !forceFinished && (
              <VoucherInvalidPage
                voucher={this.state.voucher}
                marketplaceId={this.state.customMarketplaceId}
                history={this.props.history}
                primaryColor={this.state.primaryColor}
                primaryColorLight={this.state.primaryColorLight}
                secondaryColor={this.state.secondaryColor}
                secondaryColorLight={this.state.secondaryColorLight}
              />
            )}
            <CSSTransition classNames="questionnaire__loading" in={loading} timeout={300} unmountOnExit>
              <Loading secondaryColor={this.state.secondaryColor}>
                <i className="fas fa-cog fa-spin"></i>
              </Loading>
            </CSSTransition>
          </QuestionBox>
        </Container>
      </>
    );
  }
}

export default translate()(Questionaire);
