import styled from 'styled-components';

import { PRIMARY, SECONDARY, SECONDARY_LIGHT, WHITE, PRIMARY_LIGHT } from 'constants/colors';

const buttonsTheme = {
  primary: `
    position: relative;
    background-color: PRIMARY;
    border-radius: 28px;
    border: none;
    box-shadow: 0 6px 1px 0 PRIMARY_SHADOW;
    color: ${WHITE};
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    outline: 0;
    padding: 14px 32px;
    padding-right: 47px;
    text-decoration: none;
    transition: background 400ms ease, color 400ms ease, box-shadow 400ms ease;

    &::after {
      position: absolute;
      right: 24px;
      top: 50%;
      margin-top: -8px;
      font-size: 16px;
      content: '\f061';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      opacity: 0.53;
      transition: opacity 400ms ease;
    }

    &:disabled {
      opacity: 0.4;
    }

    &:not([disabled]):hover {
      background-color: PRIMARY_LIGHT;
      box-shadow: 0 10px 6px 0 PRIMARY_SHADOW;

      &::after {
        opacity: 1;
      }
    }
  `,
  secondary: `
    position: relative;
    background-color: ${WHITE};
    border: none;
    border-radius: 28px;
    color: SECONDARY;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
    outline: 0;
    padding: 14px 32px;
    padding-right: 55px;
    text-decoration: none;
    box-shadow: 0 6px 1px 0 SECONDARY_SHADOW;
    transition: background 400ms ease, color 400ms ease, box-shadow 400ms ease;
    font-size: 20px;

    &:not([disabled]):hover {
      background-color: SECONDARY_LIGHT;
      box-shadow: 0 10px 6px 0 SECONDARY_SHADOW;

      &::after {
        opacity: 1;
      }
    }

    &::after {
      position: absolute;
      right: 24px;
      top: 50%;
      margin-top: -12px;
      font-size: 24px;
      content: '\f061';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      opacity: 0.3;
      transition: opacity 400ms ease;
    }
  `,
  header: `
    position: relative;
    background-color: ${WHITE};
    border: none;
    border-radius: 28px;
    color: SECONDARY;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
    outline: 0;
    padding: 14px 32px;
    padding-right: 47px;
    text-decoration: none;
    box-shadow: 0 6px 1px 0 rgba(0, 85, 113, 0.12);
    transition: background 400ms ease, color 400ms ease, box-shadow 400ms ease;
    font-size: 18px;

    &:not([disabled]):hover {
      background-color: SECONDARY_LIGHT;
      box-shadow: 0 10px 6px 0 rgba(0, 85, 113, 0.3);

      &::after {
      }
    }

    &::after {
      position: absolute;
      right: 24px;
      top: 50%;
      margin-top: -10px;
      content: '\f061';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      opacity: 0.3;
      transition: opacity 400ms ease;
    }
  `,
  transparent: `
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: SECONDARY;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 1px;
    outline: 0;
    text-decoration: none;

    &::after {
      font-size: 18px;
      margin: 0 10px 0 0;
      color: PRIMARY;
      opacity: 0.53;
      transition: opacity 400ms ease;
    }

    &:disabled {
      opacity: 0.4;
    }
  `,
  transparentBack: `
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 1px;
    outline: 0;
    text-decoration: none;
    color: white;

    &::before {
      left: 24px;
      top: 50%;
      font-size: 16px;
      content: '\f060';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      opacity: 0.53;
      transition: opacity 400ms ease;
      margin-right: 10px;
    }

    &:not([disabled]):hover {
      &::before {
        opacity: 1;
      }
    }

    &:disabled {
      opacity: 0.3;
    }

    @media only screen and (min-width: 2000px) {
      color: SECONDARY;
    }
  `,
  cancel: `
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 1px;
    outline: 0;
    text-decoration: none;
    border-radius: 27.5px;
    border: solid 1px #ffffff4d;
    padding: 10px 20px;
    transition: border-color 400ms ease;

    &::before {
      content: '\f00d';
      font-weight: 300;
      font-family: 'Font Awesome 5 Pro';
      font-size: 18px;
      margin: 0 10px 0 0;
      color: #fff;
      opacity: 0.53;
      transition: opacity 400ms ease;
      margin-left: -6px;
    }

    &:not([disabled]):hover {
      border-color: #ffffff;
      &::before {
        opacity: 1;
      }
    }

    &:disabled {
      opacity: 0.4;
    }
  `,
};

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const StyledButton = styled.button`
  ${(props) => {
    let css = buttonsTheme[props.variant];
    const primaryShadow = props.primaryColorLight ? hexToRgb(props.primaryColorLight) : null;
    const secondaryShadow = props.secondaryColorLight ? hexToRgb(props.secondaryColorLight) : null;

    css = primaryShadow
      ? css.replaceAll('PRIMARY_SHADOW', `rgba(${primaryShadow.r}, ${primaryShadow.g}, ${primaryShadow.b}, 0.2)`)
      : css.replaceAll('PRIMARY_SHADOW', '#0044FF1E');
    css = props.primaryColorLight
      ? css.replaceAll('PRIMARY_LIGHT', props.primaryColorLight)
      : css.replaceAll('PRIMARY_LIGHT', PRIMARY_LIGHT);
    css = props.primaryColor ? css.replaceAll('PRIMARY', props.primaryColor) : css.replaceAll('PRIMARY', PRIMARY);
    css = props.secondaryColorLight
      ? css.replaceAll('SECONDARY_LIGHT', props.secondaryColorLight)
      : css.replaceAll('SECONDARY_LIGHT', SECONDARY_LIGHT);
    css = secondaryShadow
      ? css.replaceAll('SECONDARY_SHADOW', `rgba(${primaryShadow.r}, ${primaryShadow.g}, ${primaryShadow.b}, 0.2)`)
      : css.replaceAll('SECONDARY_SHADOW', 'rgba(0, 85, 113, 0.2)');
    css = props.secondaryColor
      ? css.replaceAll('SECONDARY', props.secondaryColor)
      : css.replaceAll('SECONDARY', SECONDARY);
    return css;
  }}
`;
