import styled from 'styled-components';

import { PRIMARY, BIG_STONE } from 'constants/colors';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 400ms ease;
  box-shadow: ${(props) => (props.scrolled ? '0 2px 24px 0 rgba(0, 0, 0, 0.5)' : 'none')};
  z-index: 5;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: all 400ms linear;
    opacity: 0;
    background: linear-gradient(
      5deg,
      ${PRIMARY},
      ${(props) => (props.secondaryColor ? props.secondaryColor : BIG_STONE)}
    );
  }

  ${(props) => !props.scrolled} & {
    &:before {
      opacity: 1;
    }
  }

  opacity: 0;
  pointer-events: none;

  ${(props) => !props.visible} {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 2000px) {
    padding: 20px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;

  @media only screen and (min-width: 2000px) {
    width: 50%;
  }
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  .large {
    display: none;
    @media only screen and (min-width: 2000px) {
      display: inline;
    }
  }
`;

export const Logo = styled.img.attrs({
  src: (props) => props.logo,
  srcSet: (props) => `${props.logo} 1x, ${props.logo.replace('.png', '@2x.png')} 2x`,
})`
  align-self: center;
  width: 42px;
  height: 42px;
  object-fit: cover;
  object-position: left;

  @media only screen and (min-width: 2000px) {
    width: 367px;
    height: auto;
  }
`;
