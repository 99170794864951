import styled from 'styled-components';

import { PRIMARY, SECONDARY } from 'constants/colors';

export const SectionContainer = styled.div``;

export const TitleWrapper = styled.div`
  padding: 50px 0 20px;
  text-align: ${(props) => props.titleAlignment};
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.alignItems ? props.alignItems : 'row')};
`;

export const Title = styled.h2`
  color: ${(props) => (props.secondaryColor ? props.secondaryColor : SECONDARY)};
  display: inline-block;
  font-size: 35px;
  @media only screen and (min-width: 2000px) {
    font-size: 48px;
  }
  font-weight: 700;
  line-height: 1.29;
  margin: 0;
  position: relative;

  &:before {
    display: ${(props) => (props.titleAlignment ? 'block' : 'none')};
    position: absolute;
    content: '';
    background-color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
    border-radius: 5px;
    height: 10px;
    ${(props) =>
      props.titleAlignment === 'center' &&
      `
      margin-left: -38px;
      left: 50%;
    `}
    width: 76px;
    top: -25px;
  }
`;
