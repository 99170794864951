import styled from 'styled-components';
import { PRIMARY, REGAL_BLUE, BONDI_BLUE, TUNDORA } from 'constants/colors';

export const Container = styled.div`
  padding: 20px 20px 70px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 2000px) {
    padding: 80px 120px;
  }
`;

export const Header = styled.h4`
  font-size: 36px;
  line-height: 1.03;
  font-weight: 600;
  color: ${REGAL_BLUE};
  margin: 20px 0;
  text-align: center;
`;

export const Checkmark = styled.span`
  font-size: 52px;
  font-weight: 400;
  color: ${(props) => (props.primaryColor ? props.primaryColor : BONDI_BLUE)};
  text-align: center;
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${TUNDORA};
  margin: 0 0 20px 0;
`;

export const BulletContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const BulletText = styled(Text)`
  margin: 0;
`;

export const Bullet = styled.i`
  color: white;
  margin-right: 12px;
  background-color: ${(props) => (props.primaryColor ? props.primaryColor : PRIMARY)};
  border-radius: 50%;
  padding: 5px;
`;

export const ShareLinks = styled.div`
  margin-top: 30px;
  margin: auto;
`;

export const ShareLink = styled.a``;

export const Image = styled.img.attrs({
  src: (props) => props.src,
  srcSet: (props) => `${props.src} 1x, ${props.src.replace('.png', '@2x.png')} 2x`,
})``;

export const ShareLinkText = styled.p`
  color: ${(props) => (props.primaryColor ? props.primaryColor : BONDI_BLUE)};
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.28;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  right: 0;

  a {
    color: ${TUNDORA};
    font-size: 14px;

    i {
      color: ${PRIMARY};
      margin-right: 10px;
    }
  }
`;
