import React, { PureComponent } from 'react';
import { Container, Icon, Title, SubTitle, ContentWrapper, Statement, EmailInputWrapper } from './styles';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { translate, Trans } from 'react-i18next';
import Checkbox from 'components/atoms/Checkbox';
import { PhoneInput } from 'react-international-phone';

class SubmitFormPage extends PureComponent {
  state = { privacy_accepted: false, toc_accepted: false };

  render() {
    const {
      t,
      primaryColor,
      primaryColorLight,
      secondaryColor,
      secondaryColorLight,
      tocLink,
      vouchersEnabled,
      collectPhoneNumber,
      requirePhoneNumber,
      userCountry,
    } = this.props;
    return (
      <Container>
        <Icon primaryColor={primaryColor}>
          <i className="fal fa-envelope-open-text" />
        </Icon>
        <Title primaryColor={primaryColor}>{t('questionnaire:submit:title')}</Title>
        <ContentWrapper>
          {!vouchersEnabled && (
            <EmailInputWrapper style={{ margin: 0 }}>
              <SubTitle style={{ margin: 0, marginBottom: 10 }}>{t('questionnaire:submit:sub_title')}</SubTitle>
              <div style={{ margin: 0, marginBottom: 10 }}>
                <Input
                  type="email"
                  onChange={this.props.handleUserMail}
                  placeholder={t('questionnaire:submit:input_placeholder')}
                  value={this.props.userEmail}
                  style={{ margin: 0, marginBottom: 10 }}
                />
              </div>
            </EmailInputWrapper>
          )}

          {collectPhoneNumber && (
            <div style={{ margin: 0, marginBottom: 10 }} className={this.props.isPhoneValid ? '' : 'react-international-phone-input--error'}>
              <PhoneInput
                defaultCountry={userCountry}
                value={this.props.userPhone}
                placeholder={t('questionnaire:submit:input_placeholder_phone')}
                onChange={this.props.handleUserPhone}
              />
            </div>
          )}

          <Checkbox
            checked={this.state.privacy_accepted}
            onChange={() => this.setState({ privacy_accepted: !this.state.privacy_accepted })}
            primaryColor={primaryColor}
          >
            <Statement>
              <Trans i18nKey="questionnaire:submit:disclaimer">
                I've read the
                <a href={this.props.privacyStatementUrl} target="_blank">
                  privacy statements
                </a>
                and I accept them.
              </Trans>
            </Statement>
          </Checkbox>

          {tocLink && (
            <Checkbox
              checked={this.state.toc_accepted}
              onChange={() => this.setState({ toc_accepted: !this.state.toc_accepted })}
              primaryColor={primaryColor}
            >
              <Statement>
                <Trans i18nKey="questionnaire:submit:toc_disclaimer">
                  I've read the&nbsp;
                  <a href={tocLink} target="_blank">
                    Terms and Conditions
                  </a>
                  &nbsp;and I accept them.
                </Trans>
              </Statement>
            </Checkbox>
          )}
          <Button
            type="button"
            onClick={this.props.isEmailValid || vouchersEnabled ? this.props.handleSubmit : undefined}
            disabled={
              (!this.props.isEmailValid && !vouchersEnabled) ||
              (!this.state.toc_accepted && tocLink) ||
              !this.state.privacy_accepted ||
              (requirePhoneNumber && !this.props.isPhoneValid)
            }
            primaryColor={primaryColor}
            primaryColorLight={primaryColorLight}
            secondaryColor={secondaryColor}
            secondaryColorLight={secondaryColorLight}
          >
            {vouchersEnabled ? t('questionnaire:submit:request_report') : t('questionnaire:submit:send_report')}
          </Button>
        </ContentWrapper>
      </Container>
    );
  }
}

export default translate()(SubmitFormPage);
