import styled from 'styled-components';
import { REGAL_BLUE, BONDI_BLUE, TUNDORA } from 'constants/colors';

export const SubmitMailInput = styled.input`
  border: 1px solid ${REGAL_BLUE};
  width: 100%;
  padding: 10px;
  outline: none;
  box-shadow: none;
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  padding: 0 20px;
`;

export const Container = styled.div`
  padding: 19px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media only screen and (min-width: 2000px) {
    padding: 80px 130px 20px;
  }

  button {
    margin: 20px auto 0 auto;
  }
`;

export const Icon = styled.span`
  font-size: 52px;
  font-weight: 400;
  color: ${(props) => (props.primaryColor ? props.primaryColor : BONDI_BLUE)};
  text-align: center;
`;

export const Title = styled.h4`
  font-size: 36px;
  line-height: 1.03;
  font-weight: 600;
  color: ${(props) => (props.primaryColor ? props.primaryColor : REGAL_BLUE)};
  margin: 7px 0 20px 0;
`;

export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 1.28;
  color: ${TUNDORA};
  margin: 20px 0;
`;

export const Statement = styled.span`
  font-size: 18px;
  line-height: 1.28;
  color: ${TUNDORA};
  padding: 0 !important;
`;

export const EmailInputWrapper = styled.div`
  margin-bottom: 20px;
`;
